/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let PayRedemption = {
  async payredeemList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "pay_redemption_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at payredeemList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  async payredeemView (context, payredeemId) {
    try {      
      let post_data = new FormData();
      post_data.append('payredeem_id', payredeemId);
      return await request.curl(context, "pay_redemption_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at payredeemView() and Exception:",err.message)
    }    
  },

  async payredeemAdd (context, payredeemObj) {
    try{
      let post_data = new FormData();
    
      for (let key in payredeemObj) {
        post_data.append(key, payredeemObj[key]);
      }

      return await request.curl(context, "pay_redemption_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at payredeemAdd() and Exception:',err.message)
    }
  },

  async payredeemEdit (context, payredeemObj) {
    try{
      Utility.loadingMsg = "Processing..."
      Utility.showLoader = true

      let post_data = new FormData();
      let s3Attachments = []
      for (let key in payredeemObj) {
        if( key === 'payout_tid_img' && payredeemObj.payout_tid_img && payredeemObj.payout_tid_img.length ){
          for (let attachment of payredeemObj.payout_tid_img) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else if( key === 'invoice' && payredeemObj.invoice && payredeemObj.invoice.length ){
          for (let attachment of payredeemObj.invoice) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else {
          if (payredeemObj[key]) {
            post_data.append(key, payredeemObj[key]);
          }
        }
      }

      post_data.append('s3Attachments', JSON.stringify(s3Attachments))
      
      return await request.curl(context, "pay_redemption_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at payredeemEdit() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  async payredeemDelete (context, payredeemId) {
    try{
      let post_data = new FormData();
      
      post_data.append('payredeem_id', payredeemId);

      return await request.curl(context, "pay_redemption_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at payredeemDelete() and Exception:',err.message)
    }
  },

  async payRedemptionDashboardList (context, payRedeemObj, whereFilter) {
    try{
      let postData = new FormData()

      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      for (let key in payRedeemObj) {
        if (typeof payRedeemObj[key] == "number") {
          postData.append(key, payRedeemObj[key])
        }
        else if (payRedeemObj[key]) {
          postData.append(key, payRedeemObj[key])
        }
      }

      return await request.curl(context, "pay_redemption_dashboard_list", postData)
        .then(async response => {
          return response
        })
    }
    catch(err){
      console.log('Exception occurred at payRedemptionDashboardList() and Exception:',err.message)
    }
  },

  async payRedemptionRaiseReq (context, payRedeemObj) {
    try{
      Utility.loadingMsg = "Processing..."
      Utility.showLoader = true

      let post_data = new FormData()

      let s3Attachments = []
      for (let key in payRedeemObj) {
        if( key === 'invoice' && payRedeemObj.invoice && payRedeemObj.invoice.length ){
          for (let attachment of payRedeemObj.invoice) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else {
          if (payRedeemObj[key]) {
            post_data.append(key, payRedeemObj[key]);
          }
        }
      }
      post_data.append('s3Attachments', JSON.stringify(s3Attachments))

      return await request.curl(context, "pay_redemption_raise_req", post_data)
        .then(async response => {
          return response
        })
    }
    catch(err){
      console.log('Exception occurred at payRedemptionRaiseReq() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  async payRedemptionGetParticulars (context, payRedeemObj) {
    try{
      let postData = new FormData()

      for (let key in payRedeemObj) {
        if (typeof payRedeemObj[key] == "number") {
          postData.append(key, payRedeemObj[key])
        }
        else if (payRedeemObj[key]) {
          postData.append(key, payRedeemObj[key])
        }
      }

      return await request.curl(context, "pay_redemption_admin_particulars_get", postData)
        .then(async response => {
          return response
        })
    }
    catch(err){
      console.log('Exception occurred at payRedemptionGetParticulars() and Exception:',err.message)
    }
  },

  async payRedemptionListAdmin (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "pay_redemption_list_admin", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at payRedemptionListAdmin() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  async payRedemptionFilterGet (context) {
    try{
      let post_data = new FormData();  

      return await request.curl(context, "pay_redemption_filters_get", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at payRedemptionFilterGet() and Exception:',err.message)
    }
  },

  async payRedemptionOtpVerify (context, payRedeemObj) {
    try{
      let postData = new FormData()

      for (let key in payRedeemObj) {
        if (typeof payRedeemObj[key] == "number") {
          postData.append(key, payRedeemObj[key])
        }
        else if (payRedeemObj[key]) {
          postData.append(key, payRedeemObj[key])
        }
      }

      return await request.curl(context, "pay_redemption_otp_verify", postData)
        .then(async response => {
          return response
        })
    }
    catch(err){
      console.log('Exception occurred at payRedemptionOtpVerify() and Exception:',err.message)
    }
  },

  async payRedemptionOtpSend (context, payRedeemObj) {
    try{
      let postData = new FormData()

      for (let key in payRedeemObj) {
        if (typeof payRedeemObj[key] == "number") {
          postData.append(key, payRedeemObj[key])
        }
        else if (payRedeemObj[key]) {
          postData.append(key, payRedeemObj[key])
        }
      }

      return await request.curl(context, "pay_redemption_otp_send", postData)
        .then(async response => {
          return response
        })
    }
    catch(err){
      console.log('Exception occurred at payRedemptionOtpSend() and Exception:',err.message)
    }
  }
}

export {
  PayRedemption
}
