<template lang="">
  <div>
    <div class="row col-12 w-100 mt-2">
      <label>{{propUploadInputLabel}}</label>&nbsp;&nbsp;
      <label for="fileInputBox" class="pointer primary-color">(Click here to upload)&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label>
      <input type="file" :accept="allowedExtensions" class="dontShow" id="fileInputBox" @change="onFileInputChange($event, docsList, 'fileInputBox')">
    </div>

    <div class="row col-12 w-100 mt-2">
      <template v-if="docsList && docsList.length > 0">
        <div v-for="(attachment, index) of docsList" :key="index">
          <div class="attachmentCol mr-4">
            <span class="pointer" @click="openFile(attachment.url)">{{ index+1 }}.&nbsp;&nbsp;&nbsp;&nbsp;{{ attachment.fileName ? attachment.fileName.split("/").pop() : attachment.url.split("/").pop() }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <img class="pointer" v-if="attachment.content" :src="pdfLogo" @click="openFile(attachment.url)">
            <img v-else-if="attachment.fileName" :src="pdfLogo">
            <img v-else alt="not found">
            <span class="addIcon extraCloseIcon" @click="removeDocument(index)"><i class="fa-solid fa-circle-xmark"></i></span>
          </div>
        </div>
      </template>
    </div>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
</template>
<script>
import pdfLogo from "../assets_gide/img/logo/pdfLogo.png"

export default {
  name: "UploadDocs",
  props: {
    propUploadInputLabel: {
      type: String,
      default: "Upload Document"
    },
    propDocsList: {
      type: [Array, String],
      default: () => []
    },
    propAllowedExtensions: {
      type: String,
      default: "application/pdf,image/png,image/jpg,image/jpeg,image/gif,image/webp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
    }
  },
  data () {
    return {
      docsList: [],
      pdfLogo: pdfLogo,
      showToast: false,
      toastVariant: "default",
      toastMsg: null,
      allowedExtensions: null,
      toastTitle: "Upload Documents"
    }
  },
  mounted () {
    if (typeof this.propDocsList == "string") {
      this.docsList = JSON.parse(this.propDocsList)
    }
    else {
      this.docsList = this.propDocsList
    }
    this.allowedExtensions = this.propAllowedExtensions
  },
  methods: {
    /**
     * onFileInputChange
     */
    onFileInputChange (event, docsList, inputId) {
      try {
        const fileList = event.target.files

        if (fileList && fileList.length) {
          for (let file of fileList) {
            // Allowed file types
            let allowedExtensions = this.allowedExtensions.split(",")

            let maxSize = 5 * 1024 * 1024 // 5MB limit

            if (!allowedExtensions.includes(file.type)) {
              this.toastMsg = `Invalid file type. Please upload a supported file: ${this.allowedExtensions}`
              this.toastVariant = "danger"
              this.showToast = true
              continue
            }

            if (file.size > maxSize) {
              this.toastMsg = "File size exceeds the 5MB limit."
              this.toastVariant = "danger"
              this.showToast = true
              continue
            }

            // Read file and store in docsList
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async (e) => {
              file.url = e.currentTarget.result
              file.fileName = file.name

              if (typeof docsList === "string") {
                docsList = JSON.parse(docsList.blog_attach)
              }
              docsList.unshift(file)
              // Reset input field
              const ele = document.getElementById(inputId)
              if (ele) {
                ele.value = ""
              }

              this.$emit("emitGetLatestDocs", this.docsList)
            }
          }
        }
      }
      catch (err) {
        console.error("Exception in onFileInputChange() and err: ", err.message)
      }
    },
    /**
     * removeDocument
     */
    removeDocument (index) {
      this.docsList.splice(index, 1)
      this.$emit("emitGetLatestDocs", this.docsList)
    },
    /**
     * openFile
     */
    openFile (url) {
      if (!url) {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Invalid file url"
        return
      }

      window.open(url, "_blank")
    }
  }
}
</script>
<style lang="scss">
  .attachmentCol {
    img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      border: 2px solid aliceblue;
      margin-bottom: 6px;
    }
  }
  .extraCloseIcon {
    font-size: 18px;
    position: relative;
    top: -15px;
    cursor: pointer;
    z-index: 99;
    margin-left: -9px;
    color: #e5252c;
  }
</style>
